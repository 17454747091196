import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import MDSpinner from 'react-md-spinner';
import PdfItem from './PdfItem';
import Pager from './Pager';
import Content from './styled/Content';
import { useStores } from '../context/StoreContext';
import { ReactComponent as InstructionsImage } from '../assets/images/homeView.svg';
import { ReactComponent as InstructionsMobileImage } from '../assets/images/homeViewMobile.svg';
import FilterSidebar from './Filter/FilterSidebar';
import { orderBy } from '../settings';
import { ReactComponent as LargeUserIcon } from '../assets/images/largeUser.svg';
import {
  ListContainer,
  MainContent,
  ResultsHeader,
  SearchResultInfo,
  SortContainer,
  SortLabel,
  SortSelectWrapper,
  SortButton,
  DropdownArrowWrapper,
  DropdownList,
  DropdownItem,
  StyledDropdownArrowOpen,
  StyledDropdownArrowClosed,
  SpinContainer,
  SpinContent,
  ResultsList,
  Instructions,
  InstructionsTopSection,
  InstructionsTextContent,
  InstructionsTitle,
  InstructionsSubTitle,
  InstructionsImageWrapper,
  IconWrapper,
  TextWrapper,
  InstructionsTitleLink,
} from './styled/PdfList';

const PdfList = observer(() => {
  const { searchStore, pdfStore, uiStore, authStore } = useStores();
  const { form } = searchStore;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchStore.hasPerformedSearch) {
      uiStore.closeAllMobileMenus();
    }
  }, [searchStore.hasPerformedSearch]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSortChange = (key: string) => {
    form.setOrderby(key);
    searchStore.performSearch();
    setIsDropdownOpen(false);
    uiStore.closeAllMobileMenus();
  };

  const handleLogin = () => {
    sessionStorage.setItem('originalUrl', window.location.href);
    window.location.href = process.env.REACT_APP_ESAB_LOGIN_URL;
  };

  return (
    <ListContainer>
      {searchStore.hasPerformedSearch && <FilterSidebar />}
      <MainContent>
        <Content>
          {searchStore.hasPerformedSearch && (
            <ResultsHeader>
              <SearchResultInfo>
                Search result for{' '}
                <span>
                  &apos;{searchStore.lastSearchedTerm.replace(/"/g, '')}&apos;
                </span>
                , found {pdfStore.totalCount} results
              </SearchResultInfo>
              <SortContainer>
                <SortLabel>Sort by:</SortLabel>
                <SortSelectWrapper ref={dropdownRef}>
                  <SortButton
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {orderBy.find((option) => option.key === form.orderBy)
                      ?.value || 'Select sorting'}
                    <DropdownArrowWrapper>
                      {isDropdownOpen ? (
                        <StyledDropdownArrowOpen />
                      ) : (
                        <StyledDropdownArrowClosed />
                      )}
                    </DropdownArrowWrapper>
                  </SortButton>
                  {isDropdownOpen && (
                    <DropdownList>
                      {orderBy.map((option) => (
                        <DropdownItem
                          key={option.key}
                          onClick={() => handleSortChange(option.key)}
                        >
                          {option.value}
                        </DropdownItem>
                      ))}
                    </DropdownList>
                  )}
                </SortSelectWrapper>
              </SortContainer>
            </ResultsHeader>
          )}
          {!searchStore.hasPerformedSearch ? (
            <Instructions>
              <InstructionsTopSection>
                <InstructionsTextContent>
                  <InstructionsTitle>
                    Welcome to ESAB Manual Site
                  </InstructionsTitle>
                  <InstructionsSubTitle>
                    Here you can find manuals related to ESAB products. Start by
                    entering your desired product name or document number in the
                    search bar above.
                  </InstructionsSubTitle>
                </InstructionsTextContent>
                <InstructionsImageWrapper>
                  <IconWrapper>
                    <LargeUserIcon />
                  </IconWrapper>
                  <TextWrapper>
                    Access service information and related documents by logging
                    in the menu or click here:{' '}
                    <InstructionsTitleLink onClick={handleLogin}>
                      ESAB Partner Login
                    </InstructionsTitleLink>
                  </TextWrapper>
                </InstructionsImageWrapper>
              </InstructionsTopSection>
              {isMobile ? <InstructionsMobileImage /> : <InstructionsImage />}
            </Instructions>
          ) : !searchStore.searching && pdfStore.filteredItems.length === 0 ? (
            <Instructions></Instructions>
          ) : (
            <>
              <ResultsList>
                {pdfStore.filteredItems.map((pdf, index) => (
                  <PdfItem key={`${pdf.documentno}-${index}`} pdf={pdf} />
                ))}
              </ResultsList>
              {pdfStore.pages > 1 && (
                <Pager
                  totalPages={pdfStore.pages}
                  currentPage={pdfStore.page}
                  boundaryPagesRange={3}
                  onChange={searchStore.performSearch}
                />
              )}
            </>
          )}
        </Content>
      </MainContent>
      {searchStore.searching && (
        <SpinContainer>
          <SpinContent>
            <MDSpinner singleColor="#fee509" size={100} />
          </SpinContent>
        </SpinContainer>
      )}
    </ListContainer>
  );
});

export default PdfList;
